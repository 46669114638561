<script setup lang="ts">
import { useStorage } from '@vueuse/core'
const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const { t } = useI18n()
const viewport = useViewport()
const isMobile = computed(() => viewport.isLessThan('md'))
const toLocalePath = useLocalePath()

const { listCompanyVerificationsOnce } = useCompanyVerification()
const { getCompany } = useCompany()
// Get the managing company of this user
const { listCompanyManager } = useCompanyManager()
const managerResult = await listCompanyManager({
  page: 1,
  page_size: 10,
  order_by: '-created_date'
})

const showVerificationReminder = ref<boolean>(false)
const hasHistoricalApprovedVerifications = ref<boolean>(false)

if (managerResult && (managerResult?.data?.value?.count || 0) > 0) {
  const companyIdSlug = managerResult.data?.value?.data[0].company.id_slug
  const companyVerificationResult = await listCompanyVerificationsOnce({
    company_id_slug: companyIdSlug
  })
  const companyResult = await getCompany(companyIdSlug as string)
  const hasValidVerification = Boolean(!companyVerificationResult?.data.find((v) => v.selected))
  showVerificationReminder.value =
    Boolean(companyResult.admin_part?.need_verification) && !hasValidVerification
  hasHistoricalApprovedVerifications.value = Boolean(
    companyVerificationResult?.data.find((v) => v.status === 'APPROVED')
  )
  console.log('showVerificationReminder', showVerificationReminder.value)
  console.log('hasHistoricalApprovedVerifications', hasHistoricalApprovedVerifications.value)
}

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

useHeadSafe({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
    dir: i18nHead.value.htmlAttrs!.dir
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])]
})

const { status: authStatus, data: user } = useAuth()
const links = computed(() => {
  return isJobBoard()
    ? [
        { text: t('jobBoard.navBar.findJobs'), to: '/jobs' },
        { text: t('jobBoard.navBar.newsAndInsight'), to: '/articles' }
      ]
    : []
})

const showBanner = useStorage('showBanner', isJobBoard())

function closeBanner() {
  showBanner.value = false
}
</script>

<template>
  <div>
    <ClientOnly>
      <div v-if="showBanner" class="grid">
        <div class="col-start-1 row-start-1 h-0 min-h-full w-full overflow-hidden">
          <div class="flex h-full w-full flex-row justify-between bg-[#FFE749]">
            <NuxtImg
              :preload="false"
              src="/images/events/hktdc-edu-career-expo/banner.png"
              class="hidden h-full w-auto lg:flex"
            />
            <NuxtImg
              :preload="false"
              src="/images/events/hktdc-edu-career-expo/banner_mobile.png"
              class="flex h-full w-auto lg:hidden"
            />
            <IconsFigmaCloseOutlined
              class="hidden shrink-0 cursor-pointer pr-[1.38rem] md:flex md:w-[3rem]"
              @click="closeBanner"
            />
          </div>
        </div>

        <div
          class="gap col-start-1 row-start-1 mx-auto my-auto hidden w-full flex-col items-center justify-center text-center text-h4 font-bold text-primary md:flex md:flex-row"
        >
          <div class="my-auto flex h-[3.5rem] flex-row items-center justify-center gap-[0.62rem]">
            <div>
              <div
                class="inline cursor-pointer"
                @click="
                  navigateTo('https://www.hktdc.com/event/hkeducationexpo/en/exhibitor-list', {
                    open: {
                      target: '_blank'
                    },
                    external: true
                  })
                "
              >
                Visit HKTDC Education & Careers Expo 2025 to explore more career opportunities.
                Explore more exhibitor information HERE!
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-start-1 row-start-1 mx-auto flex w-[90vw] flex-row justify-between gap-[0.88rem] text-primary md:hidden"
        >
          <div
            class="flex flex-row items-center gap-[0.3rem] py-[1.38rem] sm:gap-[0.91rem]"
            @click="
              navigateTo('https://www.hktdc.com/event/hkeducationexpo/en/exhibitor-list', {
                open: {
                  target: '_blank'
                },
                external: true
              })
            "
          >
            <div class="mx-auto flex flex-wrap pl-[3.75rem] text-center text-h6 font-bold">
              Visit HKTDC Education & Careers Expo 2025 to explore more career opportunities.
              Explore more exhibitor information HERE!
            </div>
          </div>

          <div>
            <IconsFigmaCloseOutlined class="w-[1.5rem] cursor-pointer" @click="closeBanner" />
          </div>
        </div>
      </div>
    </ClientOnly>
    <Navbar :links="links" :active-link="route.path">
      <template #company-logo>
        <NuxtLinkLocale v-if="isJobBoard()" to="/">
          <div class="w-[7.02331rem]">
            <nuxt-picture
              format="webp"
              src="/images/tja_logo.png"
              alt="TechJobAsia Logo"
              width="220"
              height="59"
              :preload="true"
            />
          </div>
        </NuxtLinkLocale>
        <NuxtLink v-else :to="runtimeConfig.public.tlUrl">
          <div class="w-[7.02331rem]">
            <nuxt-picture
              format="webp"
              src="/images/talentlabs-logo.svg"
              alt="TalentLabs Logo"
              width="300"
              height="59"
              :preload="true"
            />
          </div>
        </NuxtLink>
      </template>
      <template #menu>
        <div v-if="authStatus === 'authenticated'" class="flex flex-row items-center gap-[1.25rem]">
          <NavAvatarDropDown :user="user" />
          <div v-if="isJobBoard()" class="hidden lg:flex">
            <ButtonBlackButton
              v-if="getPrimaryUserRole(user) === 'employer'"
              variant="outlined"
              size="small"
              :text="$t('jobBoard.navBar.forRecruiter')"
              :to="toLocalePath('/hradmin')"
              mode="navigation"
            />
          </div>
          <div v-if="isJobBoard()" class="hidden lg:flex">
            <ButtonBlackButton
              v-if="getPrimaryUserRole(user) === 'admin'"
              variant="outlined"
              size="small"
              :text="$t('jobBoard.navBar.forAdmin')"
              :to="toLocalePath('/hradmin')"
              mode="navigation"
            />
          </div>
        </div>
        <div
          v-else-if="authStatus === 'unauthenticated'"
          class="flex flex-row items-center gap-[0.62rem]"
        >
          <div class="hidden lg:flex">
            <ButtonPurpleButton
              :text="$t('jobBoard.navBar.getStarted')"
              size="small"
              mode="navigation"
              :to="toLocalePath('/auth/login?role=candidate')"
            >
              <template #right-icon>
                <IconsFigmaArrowForwardOutlined />
              </template>
            </ButtonPurpleButton>
          </div>
          <div class="lg:hidden">
            <ButtonPurpleButton
              size="small"
              :text="$t('jobBoard.navBar.getStartedShort')"
              mode="navigation"
              :to="toLocalePath('/auth/login?role=candidate')"
            />
          </div>
          <div class="hidden lg:flex">
            <ButtonBlackButton
              variant="outlined"
              size="small"
              :text="
                isMobile ? $t('jobBoard.navBar.recruiter') : $t('jobBoard.navBar.forRecruiter')
              "
              :to="toLocalePath('/auth/login?role=employer')"
              mode="navigation"
            />
          </div>
        </div>
      </template>
    </Navbar>
    <div
      v-if="user?.secret_part && user.secret_part.anonymous_signup_stage !== 'DONE'"
      class="bg-primary p-[0.88rem] text-white"
    >
      <div class="container">
        {{ $t('jobBoard.navBar.haventSetupPassword') }}
        <NuxtLinkLocale to="auth-reset-request" class="text-blue-40 hover:underline">{{
          $t('jobBoard.navBar.clickToSetupPassword')
        }}</NuxtLinkLocale>
      </div>
    </div>
    <div v-if="user && showVerificationReminder" class="bg-red-40 p-[0.88rem] text-white">
      <div class="container">
        <span v-if="hasHistoricalApprovedVerifications">
          You cannot post jobs until your business registration is renewed.
        </span>
        <span v-else
          >You cannot post jobs until you have verified your business registration.
        </span>
        <NuxtLinkLocale
          :to="{
            name: 'hradmin',
            query: { tab: 'companyVerifications' }
          }"
          ><span class="font-medium underline">
            Go renew business registration status.
          </span></NuxtLinkLocale
        >
      </div>
    </div>
    <slot />
    <LazyFooter v-if="isJobBoard()" />
  </div>
</template>

<style>
/* For view transition API */
/* .search-group {
  view-transition-name: search-group;
} */

/* .main-panel {
  view-transition-name: main-panel;
} */

/* .breadcrumb {
  view-transition-name: breadcrumb;
} */

/* .article-cover-image {
  view-transition-name: article-cover-image;
} */

/* .footer {
  view-transition-name: footer;
} */

@media (prefers-reduced-motion) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}

::view-transition-group(*),
::view-transition-old(*),
::view-transition-new(*) {
  animation-duration: 0.2s;
}

::view-transition-old(article-cover-image),
::view-transition-new(article-cover-image) {
  /* Make the height the same as the group,
  meaning the view size might not match its aspect-ratio. */
  height: 100%;
  /* Clip any overflow of the view */
  overflow: clip;
}

/* The old view is the thumbnail */
::view-transition-old(article-cover-image) {
  /* Maintain the aspect ratio of the view,
  by shrinking it to fit within the bounds of the element */
  object-fit: contain;
}

/* The new view is the full image */
::view-transition-new(article-cover-image) {
  /* Maintain the aspect ratio of the view,
  by growing it to cover the bounds of the element */
  object-fit: cover;
}
</style>
