import type { TLmsCompany } from '@tl-lms/types/company'
import type { TCompany } from '@tl-ui/components/job/JobCard.vue'
import type { components } from '@tl-lms/types/napis'

export function toCompanyPlanName(plan: string | undefined, admin: boolean): string {
  if (admin) {
    switch (plan) {
      case 'UNLIMITED_FREE_TRIAL':
        return 'Unlimited Free Trial'
      case 'LIMITED_FREE_TRIAL':
        return 'Limited Free Trial'
      case 'PAY_PER_POST':
        return 'Pay Per Post'
      case 'PAY_PER_APPLICATION':
        return 'Pay Per Application'
      case 'PAY_PER_APPLICATION_UPFRONT':
        return 'Pay Per Application Upfront'
      case 'PAY_PER_SLOT':
        return 'Pay Per Slot'
      default:
        return 'Unlimited Free Trial'
    }
  } else {
    switch (plan) {
      case 'UNLIMITED_FREE_TRIAL':
        return 'Free Trial'
      case 'LIMITED_FREE_TRIAL':
        return 'Free Trial'
      case 'PAY_PER_POST':
        return 'Pay Per Post'
      case 'PAY_PER_APPLICATION':
        return 'Pay Per Application'
      case 'PAY_PER_APPLICATION_UPFRONT':
        return 'Pay Per Application'
      case 'PAY_PER_SLOT':
        return 'Pay Per Slot'
      default:
        return 'Free Trial'
    }
  }
}

export function toCompanyIconUrl(objectId?: string): string | undefined {
  const runtimeConfig = useRuntimeConfig()
  if (!objectId) {
    return undefined
  }
  const url = `${runtimeConfig.public.s3PublicBucket}${objectId}`
  return url
}

export function toLeadSource(leadSource?: string) {
  switch (leadSource) {
    case 'SELF_ONBOARDED':
      return 'Self-onboarded'
    case 'BD_CREATED':
      return 'BD-created'
    case 'SCRAPED':
      return 'Scraped'
    default:
      return leadSource
  }
}

export function toCompanyLocation(t: any, location: string | undefined): string | undefined {
  if (!location) {
    return undefined
  }
  if (location.includes('|')) {
    const locationParts = location.split('|')
    // The last part is the region
    const region = locationParts.pop()
    // The rest is the area
    const translatedLocationParts = locationParts.map((locationPart: string) =>
      t(`jobBoard.filter.location.area.option.${locationPart}`)
    )
    const areaDisplay = translatedLocationParts.join(', ')
    const regionDisplay = t(`jobBoard.filter.location.region.option.${region}`)
    return `${areaDisplay}, ${regionDisplay}`
  } else {
    return `${t('jobBoard.filter.location.region.option.' + location.split(',')[0])}`
  }
}

export function toCompanyIndustry(t: any, industry: string): string | undefined {
  return t(`jobBoard.filter.industry.option.${industry}`)
}

export function toTCompanySEOTitle(company: TCompany): string {
  return `Job openings at ${company.name} in ${company.location}`
}

export function toTCompanySEODescription(company: TCompany): string {
  return `Working at ${company.name} in ${company.location}, Apply now! As the leading job board specializing in the tech industry, we connect talented professionals with top-tier companies offering exceptional working environments, competitive packages, and exciting career prospects.`
}

export function toTCompany(t: any, company: TLmsCompany): TCompany {
  return {
    idSlug: company.id_slug,
    to: `/companies/${company.id_slug}---${toTitleSlug(company.name)}`,
    createdAt: company.created_date || '',
    name: company.name.replace('&amp;', '&').replace('\n', ' '),
    location: toCompanyLocation(t, company.location),
    description: company.description,
    industry: toCompanyIndustry(t, company.industry),
    logo: {
      url: toCompanyIconUrl(company.logo) as string,
      width: company.logo_width,
      height: company.logo_height
    },
    banner: company.banner
      ? {
          url: toCompanyIconUrl(company.banner) as string,
          width: company.banner_width,
          height: company.banner_height
        }
      : undefined,
    instagram: company.instagram_profile,
    youtubeCoverVideo: company.youtube_cover_video,
    website: company.website,
    scale: company.scale,
    leadSource: toLeadSource(company.lead_source),
    contactPerson: company.contact_person,
    contactEmail: company.contact_email,
    contactPhone: company.contact_phone,
    archived: company.archived,
    activeJobs: company.active_jobs
  }
}

export function NAPIToTCompany(
  t: any,
  company: components['schemas']['CompanyResponseSchema']
): TCompany {
  return {
    idSlug: company.id_slug,
    to: `/companies/${company.id_slug}---${toTitleSlug(company.name)}`,
    createdAt: company.created_date || '',
    name: company.name.replace('&amp;', '&').replace('\n', ' '),
    location: toCompanyLocation(t, company.location || ''),
    description: company.description,
    industry: toCompanyIndustry(t, company.industry || ''),
    logo: company.logo_part
      ? {
          url: toCompanyIconUrl(company.logo_part?.object_key),
          width: company.logo_part?.width,
          height: company.logo_part?.height
        }
      : undefined,
    banner: company.banner_part
      ? {
          url: toCompanyIconUrl(company.banner_part?.object_key),
          width: company.banner_part?.width,
          height: company.banner_part?.height
        }
      : undefined,
    instagram: company.instagram_profile || undefined,
    youtubeCoverVideo: company.youtube_cover_video || undefined,
    website: company.website || undefined,
    scale: company.scale || undefined,
    leadSource: toLeadSource(company.admin_part?.lead_source || undefined),
    contactPerson: company.admin_part?.contact_person || undefined,
    contactEmail: company.admin_part?.contact_email || undefined,
    contactPhone: company.admin_part?.contact_phone || undefined,
    archived: company.admin_part?.archived,
    activeJobs: company.job_statistics_part?.active_jobs,
    needVerification: company.admin_part?.need_verification
  }
}

export function getImageFormat(imageUrl: string | undefined): string {
  if (imageUrl) {
    return imageUrl.split('.')[1]
  }
  return ''
}

export function getyoutubeVideoEmbedLink(link: string | undefined): string {
  if (!link) return ''
  const youtubeVideoId = link.split('v=')[1]
  return `https://www.youtube.com/embed/${youtubeVideoId}`
}

export function getInstagramEmbedLink(link: string | undefined): string {
  if (!link) return ''
  return `${link.replace(/\/$/, '')}/embed`
}
