<script setup lang="ts">
const toLocalePath = useLocalePath()
</script>
<template>
  <div
    class="group grid h-auto cursor-pointer overflow-hidden rounded-[0.63rem]"
    @click="navigateTo(toLocalePath('/hk-events/hktdc-education-career-expo'))"
  >
    <div
      class="col-start-1 row-start-1 h-0 min-h-full w-full overflow-hidden rounded-[0.63rem] bg-[#FFE749]"
    ></div>
    <div class="col-start-1 row-start-1 flex flex-col p-[1.25rem]">
      <div class="text-h4 font-bold text-primary">HKTDC Education & Career Expo 2025</div>
      <div class="h-[1.25rem] border-b border-b-primary"></div>
      <div class="flex flex-row items-center justify-between text-primary">
        <div
          class="py-[0.62rem] text-[0.875rem] text-t6 font-medium leading-[1.125rem] text-primary group-hover:underline"
        >
          Explore more tech jobs
        </div>
        <IconsFigmaArrowForwardOutlined class="h-[1.125rem] w-[1.125rem] shrink-0" />
      </div>
    </div>
  </div>
</template>
