import { default as aboutVjFawdbCGxMeta } from "/mono-repo/frontend/pages/about.vue?macro=true";
import { default as edithjJcEgGEykMeta } from "/mono-repo/frontend/pages/admin/companies/[companyId]/edit.vue?macro=true";
import { default as indexIzLUN4ov9SMeta } from "/mono-repo/frontend/pages/admin/companies/[companyId]/index.vue?macro=true";
import { default as edit1lLqSNnhgpMeta } from "/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/[jobId]/edit.vue?macro=true";
import { default as create0KancSJslxMeta } from "/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/create.vue?macro=true";
import { default as managersHhdaUzV1BAMeta } from "/mono-repo/frontend/pages/admin/companies/[companyId]/managers.vue?macro=true";
import { default as createxWC7SqUxhpMeta } from "/mono-repo/frontend/pages/admin/companies/create.vue?macro=true";
import { default as indexaD40d0XwmDMeta } from "/mono-repo/frontend/pages/admin/index.vue?macro=true";
import { default as edit6OLlsbckCsMeta } from "/mono-repo/frontend/pages/admin/job-campaigns/[campaignId]/edit.vue?macro=true";
import { default as createVKM9LxzZaKMeta } from "/mono-repo/frontend/pages/admin/job-campaigns/create.vue?macro=true";
import { default as _91id_93BeProcp9W9Meta } from "/mono-repo/frontend/pages/articles/[id].vue?macro=true";
import { default as _91category_93yZHdBHcXLvMeta } from "/mono-repo/frontend/pages/articles/categories/[category].vue?macro=true";
import { default as indexay7UHORQSoMeta } from "/mono-repo/frontend/pages/articles/index.vue?macro=true";
import { default as latestn3THY8vXQCMeta } from "/mono-repo/frontend/pages/articles/latest.vue?macro=true";
import { default as logini0gEVAtJ3TMeta } from "/mono-repo/frontend/pages/auth/login.vue?macro=true";
import { default as logoutLKUuuE4aeuMeta } from "/mono-repo/frontend/pages/auth/logout.vue?macro=true";
import { default as otp_45logincEYovVfNfsMeta } from "/mono-repo/frontend/pages/auth/otp-login.vue?macro=true";
import { default as reset_45passwordh927OL61ipMeta } from "/mono-repo/frontend/pages/auth/reset-password.vue?macro=true";
import { default as reset_45request7MFRQWEL3PMeta } from "/mono-repo/frontend/pages/auth/reset-request.vue?macro=true";
import { default as signupjGVtaeUCjwMeta } from "/mono-repo/frontend/pages/auth/signup.vue?macro=true";
import { default as _91id_93vzkUt9iQZ8Meta } from "/mono-repo/frontend/pages/companies/[id].vue?macro=true";
import { default as employerpslp09zd9PMeta } from "/mono-repo/frontend/pages/employer.vue?macro=true";
import { default as indexqRWOILn9TWMeta } from "/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/index.vue?macro=true";
import { default as registerhRgYYMtgIoMeta } from "/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/register.vue?macro=true";
import { default as thankyou8FpMNOvQ7MMeta } from "/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/thankyou.vue?macro=true";
import { default as cyberport_45career_45fair_452024OtQ5jckvHUMeta } from "/mono-repo/frontend/pages/hk-events/cyberport-career-fair-2024.vue?macro=true";
import { default as indexbjM3YwpfOaMeta } from "/mono-repo/frontend/pages/hk-events/hk-salary-survey/index.vue?macro=true";
import { default as thankyou1LV3szJKsmMeta } from "/mono-repo/frontend/pages/hk-events/hk-salary-survey/thankyou.vue?macro=true";
import { default as indexE4VQb5RrSOMeta } from "/mono-repo/frontend/pages/hk-events/hktdc-education-career-expo/index.vue?macro=true";
import { default as my_45talent_45trial_45scheme5TvkkELiRoMeta } from "/mono-repo/frontend/pages/hk-events/my-talent-trial-scheme.vue?macro=true";
import { default as indexgf9VTyfdZEMeta } from "/mono-repo/frontend/pages/hk-events/ysip/index.vue?macro=true";
import { default as editu9113WMufzMeta } from "/mono-repo/frontend/pages/hradmin/companies/[companyId]/edit.vue?macro=true";
import { default as edituqzYCp34wUMeta } from "/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/[jobId]/edit.vue?macro=true";
import { default as createwiJ58rLefnMeta } from "/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/create.vue?macro=true";
import { default as createlHvDoraHcTMeta } from "/mono-repo/frontend/pages/hradmin/companies/create.vue?macro=true";
import { default as indexajTMSjDBrGMeta } from "/mono-repo/frontend/pages/hradmin/index.vue?macro=true";
import { default as indexfham10MBRkMeta } from "/mono-repo/frontend/pages/index.vue?macro=true";
import { default as _91id_93bKUyM9UfylMeta } from "/mono-repo/frontend/pages/job-campaigns/[id].vue?macro=true";
import { default as _91id_93kVSeuP14tIMeta } from "/mono-repo/frontend/pages/jobs/[id].vue?macro=true";
import { default as indexmViOkg1KsxMeta } from "/mono-repo/frontend/pages/jobs/index.vue?macro=true";
import { default as _91title_93uQ2qkd0VL8Meta } from "/mono-repo/frontend/pages/jobs/searches/[title].vue?macro=true";
import { default as companyu2kbWHlsJ5Meta } from "/mono-repo/frontend/pages/onboard/company.vue?macro=true";
import { default as resume9QBqUrJPfDMeta } from "/mono-repo/frontend/pages/onboard/resume.vue?macro=true";
import { default as payment_45resultsLz64xtb6eMeta } from "/mono-repo/frontend/pages/payment-result.vue?macro=true";
import { default as privacy_45policyb4icD8scJ8Meta } from "/mono-repo/frontend/pages/privacy-policy.vue?macro=true";
import { default as indexEiyAg9I6W9Meta } from "/mono-repo/frontend/pages/profile/index.vue?macro=true";
import { default as companiesanUQlyIq77Meta } from "/mono-repo/frontend/pages/sitemap/companies.vue?macro=true";
import { default as job_45campaigns16gJ9GfHzuMeta } from "/mono-repo/frontend/pages/sitemap/job-campaigns.vue?macro=true";
import { default as jobsEh1fEvpUHUMeta } from "/mono-repo/frontend/pages/sitemap/jobs.vue?macro=true";
import { default as popular_45searchesX0BqCBu0erMeta } from "/mono-repo/frontend/pages/sitemap/popular-searches.vue?macro=true";
import { default as termsAOJa1iRFbaMeta } from "/mono-repo/frontend/pages/terms.vue?macro=true";
import { default as component_45stubkZ42uMcRISMeta } from "/mono-repo/frontend/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubkZ42uMcRIS } from "/mono-repo/frontend/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: aboutVjFawdbCGxMeta?.name ?? "about___en",
    path: aboutVjFawdbCGxMeta?.path ?? "/about",
    meta: aboutVjFawdbCGxMeta || {},
    alias: aboutVjFawdbCGxMeta?.alias || [],
    redirect: aboutVjFawdbCGxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutVjFawdbCGxMeta?.name ?? "about___zh-Hant",
    path: aboutVjFawdbCGxMeta?.path ?? "/zh-Hant/about",
    meta: aboutVjFawdbCGxMeta || {},
    alias: aboutVjFawdbCGxMeta?.alias || [],
    redirect: aboutVjFawdbCGxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutVjFawdbCGxMeta?.name ?? "about___zh-Hans",
    path: aboutVjFawdbCGxMeta?.path ?? "/zh-Hans/about",
    meta: aboutVjFawdbCGxMeta || {},
    alias: aboutVjFawdbCGxMeta?.alias || [],
    redirect: aboutVjFawdbCGxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: edithjJcEgGEykMeta?.name ?? "admin-companies-companyId-edit___en",
    path: edithjJcEgGEykMeta?.path ?? "/admin/companies/:companyId()/edit",
    meta: edithjJcEgGEykMeta || {},
    alias: edithjJcEgGEykMeta?.alias || [],
    redirect: edithjJcEgGEykMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edithjJcEgGEykMeta?.name ?? "admin-companies-companyId-edit___zh-Hant",
    path: edithjJcEgGEykMeta?.path ?? "/zh-Hant/admin/companies/:companyId()/edit",
    meta: edithjJcEgGEykMeta || {},
    alias: edithjJcEgGEykMeta?.alias || [],
    redirect: edithjJcEgGEykMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edithjJcEgGEykMeta?.name ?? "admin-companies-companyId-edit___zh-Hans",
    path: edithjJcEgGEykMeta?.path ?? "/zh-Hans/admin/companies/:companyId()/edit",
    meta: edithjJcEgGEykMeta || {},
    alias: edithjJcEgGEykMeta?.alias || [],
    redirect: edithjJcEgGEykMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexIzLUN4ov9SMeta?.name ?? "admin-companies-companyId___en",
    path: indexIzLUN4ov9SMeta?.path ?? "/admin/companies/:companyId()",
    meta: indexIzLUN4ov9SMeta || {},
    alias: indexIzLUN4ov9SMeta?.alias || [],
    redirect: indexIzLUN4ov9SMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexIzLUN4ov9SMeta?.name ?? "admin-companies-companyId___zh-Hant",
    path: indexIzLUN4ov9SMeta?.path ?? "/zh-Hant/admin/companies/:companyId()",
    meta: indexIzLUN4ov9SMeta || {},
    alias: indexIzLUN4ov9SMeta?.alias || [],
    redirect: indexIzLUN4ov9SMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexIzLUN4ov9SMeta?.name ?? "admin-companies-companyId___zh-Hans",
    path: indexIzLUN4ov9SMeta?.path ?? "/zh-Hans/admin/companies/:companyId()",
    meta: indexIzLUN4ov9SMeta || {},
    alias: indexIzLUN4ov9SMeta?.alias || [],
    redirect: indexIzLUN4ov9SMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/index.vue").then(m => m.default || m)
  },
  {
    name: edit1lLqSNnhgpMeta?.name ?? "admin-companies-companyId-jobs-jobId-edit___en",
    path: edit1lLqSNnhgpMeta?.path ?? "/admin/companies/:companyId()/jobs/:jobId()/edit",
    meta: edit1lLqSNnhgpMeta || {},
    alias: edit1lLqSNnhgpMeta?.alias || [],
    redirect: edit1lLqSNnhgpMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/[jobId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit1lLqSNnhgpMeta?.name ?? "admin-companies-companyId-jobs-jobId-edit___zh-Hant",
    path: edit1lLqSNnhgpMeta?.path ?? "/zh-Hant/admin/companies/:companyId()/jobs/:jobId()/edit",
    meta: edit1lLqSNnhgpMeta || {},
    alias: edit1lLqSNnhgpMeta?.alias || [],
    redirect: edit1lLqSNnhgpMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/[jobId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit1lLqSNnhgpMeta?.name ?? "admin-companies-companyId-jobs-jobId-edit___zh-Hans",
    path: edit1lLqSNnhgpMeta?.path ?? "/zh-Hans/admin/companies/:companyId()/jobs/:jobId()/edit",
    meta: edit1lLqSNnhgpMeta || {},
    alias: edit1lLqSNnhgpMeta?.alias || [],
    redirect: edit1lLqSNnhgpMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/[jobId]/edit.vue").then(m => m.default || m)
  },
  {
    name: create0KancSJslxMeta?.name ?? "admin-companies-companyId-jobs-create___en",
    path: create0KancSJslxMeta?.path ?? "/admin/companies/:companyId()/jobs/create",
    meta: create0KancSJslxMeta || {},
    alias: create0KancSJslxMeta?.alias || [],
    redirect: create0KancSJslxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: create0KancSJslxMeta?.name ?? "admin-companies-companyId-jobs-create___zh-Hant",
    path: create0KancSJslxMeta?.path ?? "/zh-Hant/admin/companies/:companyId()/jobs/create",
    meta: create0KancSJslxMeta || {},
    alias: create0KancSJslxMeta?.alias || [],
    redirect: create0KancSJslxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: create0KancSJslxMeta?.name ?? "admin-companies-companyId-jobs-create___zh-Hans",
    path: create0KancSJslxMeta?.path ?? "/zh-Hans/admin/companies/:companyId()/jobs/create",
    meta: create0KancSJslxMeta || {},
    alias: create0KancSJslxMeta?.alias || [],
    redirect: create0KancSJslxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: managersHhdaUzV1BAMeta?.name ?? "admin-companies-companyId-managers___en",
    path: managersHhdaUzV1BAMeta?.path ?? "/admin/companies/:companyId()/managers",
    meta: managersHhdaUzV1BAMeta || {},
    alias: managersHhdaUzV1BAMeta?.alias || [],
    redirect: managersHhdaUzV1BAMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/managers.vue").then(m => m.default || m)
  },
  {
    name: managersHhdaUzV1BAMeta?.name ?? "admin-companies-companyId-managers___zh-Hant",
    path: managersHhdaUzV1BAMeta?.path ?? "/zh-Hant/admin/companies/:companyId()/managers",
    meta: managersHhdaUzV1BAMeta || {},
    alias: managersHhdaUzV1BAMeta?.alias || [],
    redirect: managersHhdaUzV1BAMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/managers.vue").then(m => m.default || m)
  },
  {
    name: managersHhdaUzV1BAMeta?.name ?? "admin-companies-companyId-managers___zh-Hans",
    path: managersHhdaUzV1BAMeta?.path ?? "/zh-Hans/admin/companies/:companyId()/managers",
    meta: managersHhdaUzV1BAMeta || {},
    alias: managersHhdaUzV1BAMeta?.alias || [],
    redirect: managersHhdaUzV1BAMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/[companyId]/managers.vue").then(m => m.default || m)
  },
  {
    name: createxWC7SqUxhpMeta?.name ?? "admin-companies-create___en",
    path: createxWC7SqUxhpMeta?.path ?? "/admin/companies/create",
    meta: createxWC7SqUxhpMeta || {},
    alias: createxWC7SqUxhpMeta?.alias || [],
    redirect: createxWC7SqUxhpMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createxWC7SqUxhpMeta?.name ?? "admin-companies-create___zh-Hant",
    path: createxWC7SqUxhpMeta?.path ?? "/zh-Hant/admin/companies/create",
    meta: createxWC7SqUxhpMeta || {},
    alias: createxWC7SqUxhpMeta?.alias || [],
    redirect: createxWC7SqUxhpMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createxWC7SqUxhpMeta?.name ?? "admin-companies-create___zh-Hans",
    path: createxWC7SqUxhpMeta?.path ?? "/zh-Hans/admin/companies/create",
    meta: createxWC7SqUxhpMeta || {},
    alias: createxWC7SqUxhpMeta?.alias || [],
    redirect: createxWC7SqUxhpMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: indexaD40d0XwmDMeta?.name ?? "admin___en",
    path: indexaD40d0XwmDMeta?.path ?? "/admin",
    meta: indexaD40d0XwmDMeta || {},
    alias: indexaD40d0XwmDMeta?.alias || [],
    redirect: indexaD40d0XwmDMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexaD40d0XwmDMeta?.name ?? "admin___zh-Hant",
    path: indexaD40d0XwmDMeta?.path ?? "/zh-Hant/admin",
    meta: indexaD40d0XwmDMeta || {},
    alias: indexaD40d0XwmDMeta?.alias || [],
    redirect: indexaD40d0XwmDMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexaD40d0XwmDMeta?.name ?? "admin___zh-Hans",
    path: indexaD40d0XwmDMeta?.path ?? "/zh-Hans/admin",
    meta: indexaD40d0XwmDMeta || {},
    alias: indexaD40d0XwmDMeta?.alias || [],
    redirect: indexaD40d0XwmDMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: edit6OLlsbckCsMeta?.name ?? "admin-job-campaigns-campaignId-edit___en",
    path: edit6OLlsbckCsMeta?.path ?? "/admin/job-campaigns/:campaignId()/edit",
    meta: edit6OLlsbckCsMeta || {},
    alias: edit6OLlsbckCsMeta?.alias || [],
    redirect: edit6OLlsbckCsMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/job-campaigns/[campaignId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit6OLlsbckCsMeta?.name ?? "admin-job-campaigns-campaignId-edit___zh-Hant",
    path: edit6OLlsbckCsMeta?.path ?? "/zh-Hant/admin/job-campaigns/:campaignId()/edit",
    meta: edit6OLlsbckCsMeta || {},
    alias: edit6OLlsbckCsMeta?.alias || [],
    redirect: edit6OLlsbckCsMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/job-campaigns/[campaignId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edit6OLlsbckCsMeta?.name ?? "admin-job-campaigns-campaignId-edit___zh-Hans",
    path: edit6OLlsbckCsMeta?.path ?? "/zh-Hans/admin/job-campaigns/:campaignId()/edit",
    meta: edit6OLlsbckCsMeta || {},
    alias: edit6OLlsbckCsMeta?.alias || [],
    redirect: edit6OLlsbckCsMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/job-campaigns/[campaignId]/edit.vue").then(m => m.default || m)
  },
  {
    name: createVKM9LxzZaKMeta?.name ?? "admin-job-campaigns-create___en",
    path: createVKM9LxzZaKMeta?.path ?? "/admin/job-campaigns/create",
    meta: createVKM9LxzZaKMeta || {},
    alias: createVKM9LxzZaKMeta?.alias || [],
    redirect: createVKM9LxzZaKMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/job-campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: createVKM9LxzZaKMeta?.name ?? "admin-job-campaigns-create___zh-Hant",
    path: createVKM9LxzZaKMeta?.path ?? "/zh-Hant/admin/job-campaigns/create",
    meta: createVKM9LxzZaKMeta || {},
    alias: createVKM9LxzZaKMeta?.alias || [],
    redirect: createVKM9LxzZaKMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/job-campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: createVKM9LxzZaKMeta?.name ?? "admin-job-campaigns-create___zh-Hans",
    path: createVKM9LxzZaKMeta?.path ?? "/zh-Hans/admin/job-campaigns/create",
    meta: createVKM9LxzZaKMeta || {},
    alias: createVKM9LxzZaKMeta?.alias || [],
    redirect: createVKM9LxzZaKMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/admin/job-campaigns/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BeProcp9W9Meta?.name ?? "articles-id___en",
    path: _91id_93BeProcp9W9Meta?.path ?? "/articles/:id()",
    meta: _91id_93BeProcp9W9Meta || {},
    alias: _91id_93BeProcp9W9Meta?.alias || [],
    redirect: _91id_93BeProcp9W9Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BeProcp9W9Meta?.name ?? "articles-id___zh-Hant",
    path: _91id_93BeProcp9W9Meta?.path ?? "/zh-Hant/articles/:id()",
    meta: _91id_93BeProcp9W9Meta || {},
    alias: _91id_93BeProcp9W9Meta?.alias || [],
    redirect: _91id_93BeProcp9W9Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BeProcp9W9Meta?.name ?? "articles-id___zh-Hans",
    path: _91id_93BeProcp9W9Meta?.path ?? "/zh-Hans/articles/:id()",
    meta: _91id_93BeProcp9W9Meta || {},
    alias: _91id_93BeProcp9W9Meta?.alias || [],
    redirect: _91id_93BeProcp9W9Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: _91category_93yZHdBHcXLvMeta?.name ?? "articles-categories-category___en",
    path: _91category_93yZHdBHcXLvMeta?.path ?? "/articles/categories/:category()",
    meta: _91category_93yZHdBHcXLvMeta || {},
    alias: _91category_93yZHdBHcXLvMeta?.alias || [],
    redirect: _91category_93yZHdBHcXLvMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/categories/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93yZHdBHcXLvMeta?.name ?? "articles-categories-category___zh-Hant",
    path: _91category_93yZHdBHcXLvMeta?.path ?? "/zh-Hant/articles/categories/:category()",
    meta: _91category_93yZHdBHcXLvMeta || {},
    alias: _91category_93yZHdBHcXLvMeta?.alias || [],
    redirect: _91category_93yZHdBHcXLvMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/categories/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93yZHdBHcXLvMeta?.name ?? "articles-categories-category___zh-Hans",
    path: _91category_93yZHdBHcXLvMeta?.path ?? "/zh-Hans/articles/categories/:category()",
    meta: _91category_93yZHdBHcXLvMeta || {},
    alias: _91category_93yZHdBHcXLvMeta?.alias || [],
    redirect: _91category_93yZHdBHcXLvMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/categories/[category].vue").then(m => m.default || m)
  },
  {
    name: indexay7UHORQSoMeta?.name ?? "articles___en",
    path: indexay7UHORQSoMeta?.path ?? "/articles",
    meta: indexay7UHORQSoMeta || {},
    alias: indexay7UHORQSoMeta?.alias || [],
    redirect: indexay7UHORQSoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexay7UHORQSoMeta?.name ?? "articles___zh-Hant",
    path: indexay7UHORQSoMeta?.path ?? "/zh-Hant/articles",
    meta: indexay7UHORQSoMeta || {},
    alias: indexay7UHORQSoMeta?.alias || [],
    redirect: indexay7UHORQSoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexay7UHORQSoMeta?.name ?? "articles___zh-Hans",
    path: indexay7UHORQSoMeta?.path ?? "/zh-Hans/articles",
    meta: indexay7UHORQSoMeta || {},
    alias: indexay7UHORQSoMeta?.alias || [],
    redirect: indexay7UHORQSoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: latestn3THY8vXQCMeta?.name ?? "articles-latest___en",
    path: latestn3THY8vXQCMeta?.path ?? "/articles/latest",
    meta: latestn3THY8vXQCMeta || {},
    alias: latestn3THY8vXQCMeta?.alias || [],
    redirect: latestn3THY8vXQCMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/latest.vue").then(m => m.default || m)
  },
  {
    name: latestn3THY8vXQCMeta?.name ?? "articles-latest___zh-Hant",
    path: latestn3THY8vXQCMeta?.path ?? "/zh-Hant/articles/latest",
    meta: latestn3THY8vXQCMeta || {},
    alias: latestn3THY8vXQCMeta?.alias || [],
    redirect: latestn3THY8vXQCMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/latest.vue").then(m => m.default || m)
  },
  {
    name: latestn3THY8vXQCMeta?.name ?? "articles-latest___zh-Hans",
    path: latestn3THY8vXQCMeta?.path ?? "/zh-Hans/articles/latest",
    meta: latestn3THY8vXQCMeta || {},
    alias: latestn3THY8vXQCMeta?.alias || [],
    redirect: latestn3THY8vXQCMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/articles/latest.vue").then(m => m.default || m)
  },
  {
    name: logini0gEVAtJ3TMeta?.name ?? "auth-login___en",
    path: logini0gEVAtJ3TMeta?.path ?? "/auth/login",
    meta: logini0gEVAtJ3TMeta || {},
    alias: logini0gEVAtJ3TMeta?.alias || [],
    redirect: logini0gEVAtJ3TMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logini0gEVAtJ3TMeta?.name ?? "auth-login___zh-Hant",
    path: logini0gEVAtJ3TMeta?.path ?? "/zh-Hant/auth/login",
    meta: logini0gEVAtJ3TMeta || {},
    alias: logini0gEVAtJ3TMeta?.alias || [],
    redirect: logini0gEVAtJ3TMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logini0gEVAtJ3TMeta?.name ?? "auth-login___zh-Hans",
    path: logini0gEVAtJ3TMeta?.path ?? "/zh-Hans/auth/login",
    meta: logini0gEVAtJ3TMeta || {},
    alias: logini0gEVAtJ3TMeta?.alias || [],
    redirect: logini0gEVAtJ3TMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutLKUuuE4aeuMeta?.name ?? "auth-logout___en",
    path: logoutLKUuuE4aeuMeta?.path ?? "/auth/logout",
    meta: logoutLKUuuE4aeuMeta || {},
    alias: logoutLKUuuE4aeuMeta?.alias || [],
    redirect: logoutLKUuuE4aeuMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutLKUuuE4aeuMeta?.name ?? "auth-logout___zh-Hant",
    path: logoutLKUuuE4aeuMeta?.path ?? "/zh-Hant/auth/logout",
    meta: logoutLKUuuE4aeuMeta || {},
    alias: logoutLKUuuE4aeuMeta?.alias || [],
    redirect: logoutLKUuuE4aeuMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutLKUuuE4aeuMeta?.name ?? "auth-logout___zh-Hans",
    path: logoutLKUuuE4aeuMeta?.path ?? "/zh-Hans/auth/logout",
    meta: logoutLKUuuE4aeuMeta || {},
    alias: logoutLKUuuE4aeuMeta?.alias || [],
    redirect: logoutLKUuuE4aeuMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: otp_45logincEYovVfNfsMeta?.name ?? "auth-otp-login___en",
    path: otp_45logincEYovVfNfsMeta?.path ?? "/auth/otp-login",
    meta: otp_45logincEYovVfNfsMeta || {},
    alias: otp_45logincEYovVfNfsMeta?.alias || [],
    redirect: otp_45logincEYovVfNfsMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/otp-login.vue").then(m => m.default || m)
  },
  {
    name: otp_45logincEYovVfNfsMeta?.name ?? "auth-otp-login___zh-Hant",
    path: otp_45logincEYovVfNfsMeta?.path ?? "/zh-Hant/auth/otp-login",
    meta: otp_45logincEYovVfNfsMeta || {},
    alias: otp_45logincEYovVfNfsMeta?.alias || [],
    redirect: otp_45logincEYovVfNfsMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/otp-login.vue").then(m => m.default || m)
  },
  {
    name: otp_45logincEYovVfNfsMeta?.name ?? "auth-otp-login___zh-Hans",
    path: otp_45logincEYovVfNfsMeta?.path ?? "/zh-Hans/auth/otp-login",
    meta: otp_45logincEYovVfNfsMeta || {},
    alias: otp_45logincEYovVfNfsMeta?.alias || [],
    redirect: otp_45logincEYovVfNfsMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/otp-login.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordh927OL61ipMeta?.name ?? "auth-reset-password___en",
    path: reset_45passwordh927OL61ipMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordh927OL61ipMeta || {},
    alias: reset_45passwordh927OL61ipMeta?.alias || [],
    redirect: reset_45passwordh927OL61ipMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordh927OL61ipMeta?.name ?? "auth-reset-password___zh-Hant",
    path: reset_45passwordh927OL61ipMeta?.path ?? "/zh-Hant/auth/reset-password",
    meta: reset_45passwordh927OL61ipMeta || {},
    alias: reset_45passwordh927OL61ipMeta?.alias || [],
    redirect: reset_45passwordh927OL61ipMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordh927OL61ipMeta?.name ?? "auth-reset-password___zh-Hans",
    path: reset_45passwordh927OL61ipMeta?.path ?? "/zh-Hans/auth/reset-password",
    meta: reset_45passwordh927OL61ipMeta || {},
    alias: reset_45passwordh927OL61ipMeta?.alias || [],
    redirect: reset_45passwordh927OL61ipMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45request7MFRQWEL3PMeta?.name ?? "auth-reset-request___en",
    path: reset_45request7MFRQWEL3PMeta?.path ?? "/auth/reset-request",
    meta: reset_45request7MFRQWEL3PMeta || {},
    alias: reset_45request7MFRQWEL3PMeta?.alias || [],
    redirect: reset_45request7MFRQWEL3PMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/reset-request.vue").then(m => m.default || m)
  },
  {
    name: reset_45request7MFRQWEL3PMeta?.name ?? "auth-reset-request___zh-Hant",
    path: reset_45request7MFRQWEL3PMeta?.path ?? "/zh-Hant/auth/reset-request",
    meta: reset_45request7MFRQWEL3PMeta || {},
    alias: reset_45request7MFRQWEL3PMeta?.alias || [],
    redirect: reset_45request7MFRQWEL3PMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/reset-request.vue").then(m => m.default || m)
  },
  {
    name: reset_45request7MFRQWEL3PMeta?.name ?? "auth-reset-request___zh-Hans",
    path: reset_45request7MFRQWEL3PMeta?.path ?? "/zh-Hans/auth/reset-request",
    meta: reset_45request7MFRQWEL3PMeta || {},
    alias: reset_45request7MFRQWEL3PMeta?.alias || [],
    redirect: reset_45request7MFRQWEL3PMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/reset-request.vue").then(m => m.default || m)
  },
  {
    name: signupjGVtaeUCjwMeta?.name ?? "auth-signup___en",
    path: signupjGVtaeUCjwMeta?.path ?? "/auth/signup",
    meta: signupjGVtaeUCjwMeta || {},
    alias: signupjGVtaeUCjwMeta?.alias || [],
    redirect: signupjGVtaeUCjwMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: signupjGVtaeUCjwMeta?.name ?? "auth-signup___zh-Hant",
    path: signupjGVtaeUCjwMeta?.path ?? "/zh-Hant/auth/signup",
    meta: signupjGVtaeUCjwMeta || {},
    alias: signupjGVtaeUCjwMeta?.alias || [],
    redirect: signupjGVtaeUCjwMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: signupjGVtaeUCjwMeta?.name ?? "auth-signup___zh-Hans",
    path: signupjGVtaeUCjwMeta?.path ?? "/zh-Hans/auth/signup",
    meta: signupjGVtaeUCjwMeta || {},
    alias: signupjGVtaeUCjwMeta?.alias || [],
    redirect: signupjGVtaeUCjwMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vzkUt9iQZ8Meta?.name ?? "companies-id___en",
    path: _91id_93vzkUt9iQZ8Meta?.path ?? "/companies/:id()",
    meta: _91id_93vzkUt9iQZ8Meta || {},
    alias: _91id_93vzkUt9iQZ8Meta?.alias || [],
    redirect: _91id_93vzkUt9iQZ8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vzkUt9iQZ8Meta?.name ?? "companies-id___zh-Hant",
    path: _91id_93vzkUt9iQZ8Meta?.path ?? "/zh-Hant/companies/:id()",
    meta: _91id_93vzkUt9iQZ8Meta || {},
    alias: _91id_93vzkUt9iQZ8Meta?.alias || [],
    redirect: _91id_93vzkUt9iQZ8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vzkUt9iQZ8Meta?.name ?? "companies-id___zh-Hans",
    path: _91id_93vzkUt9iQZ8Meta?.path ?? "/zh-Hans/companies/:id()",
    meta: _91id_93vzkUt9iQZ8Meta || {},
    alias: _91id_93vzkUt9iQZ8Meta?.alias || [],
    redirect: _91id_93vzkUt9iQZ8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/companies/[id].vue").then(m => m.default || m)
  },
  {
    name: employerpslp09zd9PMeta?.name ?? "employer___en",
    path: employerpslp09zd9PMeta?.path ?? "/employer",
    meta: employerpslp09zd9PMeta || {},
    alias: employerpslp09zd9PMeta?.alias || [],
    redirect: employerpslp09zd9PMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/employer.vue").then(m => m.default || m)
  },
  {
    name: employerpslp09zd9PMeta?.name ?? "employer___zh-Hant",
    path: employerpslp09zd9PMeta?.path ?? "/zh-Hant/employer",
    meta: employerpslp09zd9PMeta || {},
    alias: employerpslp09zd9PMeta?.alias || [],
    redirect: employerpslp09zd9PMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/employer.vue").then(m => m.default || m)
  },
  {
    name: employerpslp09zd9PMeta?.name ?? "employer___zh-Hans",
    path: employerpslp09zd9PMeta?.path ?? "/zh-Hans/employer",
    meta: employerpslp09zd9PMeta || {},
    alias: employerpslp09zd9PMeta?.alias || [],
    redirect: employerpslp09zd9PMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/employer.vue").then(m => m.default || m)
  },
  {
    name: indexqRWOILn9TWMeta?.name ?? "hk-events-aws-discounted-exam-2024___en",
    path: indexqRWOILn9TWMeta?.path ?? "/hk-events/aws-discounted-exam-2024",
    meta: indexqRWOILn9TWMeta || {},
    alias: indexqRWOILn9TWMeta?.alias || [],
    redirect: indexqRWOILn9TWMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/index.vue").then(m => m.default || m)
  },
  {
    name: indexqRWOILn9TWMeta?.name ?? "hk-events-aws-discounted-exam-2024___zh-Hant",
    path: indexqRWOILn9TWMeta?.path ?? "/zh-Hant/hk-events/aws-discounted-exam-2024",
    meta: indexqRWOILn9TWMeta || {},
    alias: indexqRWOILn9TWMeta?.alias || [],
    redirect: indexqRWOILn9TWMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/index.vue").then(m => m.default || m)
  },
  {
    name: indexqRWOILn9TWMeta?.name ?? "hk-events-aws-discounted-exam-2024___zh-Hans",
    path: indexqRWOILn9TWMeta?.path ?? "/zh-Hans/hk-events/aws-discounted-exam-2024",
    meta: indexqRWOILn9TWMeta || {},
    alias: indexqRWOILn9TWMeta?.alias || [],
    redirect: indexqRWOILn9TWMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/index.vue").then(m => m.default || m)
  },
  {
    name: registerhRgYYMtgIoMeta?.name ?? "hk-events-aws-discounted-exam-2024-register___en",
    path: registerhRgYYMtgIoMeta?.path ?? "/hk-events/aws-discounted-exam-2024/register",
    meta: registerhRgYYMtgIoMeta || {},
    alias: registerhRgYYMtgIoMeta?.alias || [],
    redirect: registerhRgYYMtgIoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/register.vue").then(m => m.default || m)
  },
  {
    name: registerhRgYYMtgIoMeta?.name ?? "hk-events-aws-discounted-exam-2024-register___zh-Hant",
    path: registerhRgYYMtgIoMeta?.path ?? "/zh-Hant/hk-events/aws-discounted-exam-2024/register",
    meta: registerhRgYYMtgIoMeta || {},
    alias: registerhRgYYMtgIoMeta?.alias || [],
    redirect: registerhRgYYMtgIoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/register.vue").then(m => m.default || m)
  },
  {
    name: registerhRgYYMtgIoMeta?.name ?? "hk-events-aws-discounted-exam-2024-register___zh-Hans",
    path: registerhRgYYMtgIoMeta?.path ?? "/zh-Hans/hk-events/aws-discounted-exam-2024/register",
    meta: registerhRgYYMtgIoMeta || {},
    alias: registerhRgYYMtgIoMeta?.alias || [],
    redirect: registerhRgYYMtgIoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/register.vue").then(m => m.default || m)
  },
  {
    name: thankyou8FpMNOvQ7MMeta?.name ?? "hk-events-aws-discounted-exam-2024-thankyou___en",
    path: thankyou8FpMNOvQ7MMeta?.path ?? "/hk-events/aws-discounted-exam-2024/thankyou",
    meta: thankyou8FpMNOvQ7MMeta || {},
    alias: thankyou8FpMNOvQ7MMeta?.alias || [],
    redirect: thankyou8FpMNOvQ7MMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyou8FpMNOvQ7MMeta?.name ?? "hk-events-aws-discounted-exam-2024-thankyou___zh-Hant",
    path: thankyou8FpMNOvQ7MMeta?.path ?? "/zh-Hant/hk-events/aws-discounted-exam-2024/thankyou",
    meta: thankyou8FpMNOvQ7MMeta || {},
    alias: thankyou8FpMNOvQ7MMeta?.alias || [],
    redirect: thankyou8FpMNOvQ7MMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyou8FpMNOvQ7MMeta?.name ?? "hk-events-aws-discounted-exam-2024-thankyou___zh-Hans",
    path: thankyou8FpMNOvQ7MMeta?.path ?? "/zh-Hans/hk-events/aws-discounted-exam-2024/thankyou",
    meta: thankyou8FpMNOvQ7MMeta || {},
    alias: thankyou8FpMNOvQ7MMeta?.alias || [],
    redirect: thankyou8FpMNOvQ7MMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/aws-discounted-exam-2024/thankyou.vue").then(m => m.default || m)
  },
  {
    name: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.name ?? "hk-events-cyberport-career-fair-2024___en",
    path: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.path ?? "/hk-events/cyberport-career-fair-2024",
    meta: cyberport_45career_45fair_452024OtQ5jckvHUMeta || {},
    alias: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.alias || [],
    redirect: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/cyberport-career-fair-2024.vue").then(m => m.default || m)
  },
  {
    name: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.name ?? "hk-events-cyberport-career-fair-2024___zh-Hant",
    path: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.path ?? "/zh-Hant/hk-events/cyberport-career-fair-2024",
    meta: cyberport_45career_45fair_452024OtQ5jckvHUMeta || {},
    alias: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.alias || [],
    redirect: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/cyberport-career-fair-2024.vue").then(m => m.default || m)
  },
  {
    name: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.name ?? "hk-events-cyberport-career-fair-2024___zh-Hans",
    path: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.path ?? "/zh-Hans/hk-events/cyberport-career-fair-2024",
    meta: cyberport_45career_45fair_452024OtQ5jckvHUMeta || {},
    alias: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.alias || [],
    redirect: cyberport_45career_45fair_452024OtQ5jckvHUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/cyberport-career-fair-2024.vue").then(m => m.default || m)
  },
  {
    name: indexbjM3YwpfOaMeta?.name ?? "hk-events-hk-salary-survey___en",
    path: indexbjM3YwpfOaMeta?.path ?? "/hk-events/hk-salary-survey",
    meta: indexbjM3YwpfOaMeta || {},
    alias: indexbjM3YwpfOaMeta?.alias || [],
    redirect: indexbjM3YwpfOaMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hk-salary-survey/index.vue").then(m => m.default || m)
  },
  {
    name: indexbjM3YwpfOaMeta?.name ?? "hk-events-hk-salary-survey___zh-Hant",
    path: indexbjM3YwpfOaMeta?.path ?? "/zh-Hant/hk-events/hk-salary-survey",
    meta: indexbjM3YwpfOaMeta || {},
    alias: indexbjM3YwpfOaMeta?.alias || [],
    redirect: indexbjM3YwpfOaMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hk-salary-survey/index.vue").then(m => m.default || m)
  },
  {
    name: indexbjM3YwpfOaMeta?.name ?? "hk-events-hk-salary-survey___zh-Hans",
    path: indexbjM3YwpfOaMeta?.path ?? "/zh-Hans/hk-events/hk-salary-survey",
    meta: indexbjM3YwpfOaMeta || {},
    alias: indexbjM3YwpfOaMeta?.alias || [],
    redirect: indexbjM3YwpfOaMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hk-salary-survey/index.vue").then(m => m.default || m)
  },
  {
    name: thankyou1LV3szJKsmMeta?.name ?? "hk-events-hk-salary-survey-thankyou___en",
    path: thankyou1LV3szJKsmMeta?.path ?? "/hk-events/hk-salary-survey/thankyou",
    meta: thankyou1LV3szJKsmMeta || {},
    alias: thankyou1LV3szJKsmMeta?.alias || [],
    redirect: thankyou1LV3szJKsmMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hk-salary-survey/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyou1LV3szJKsmMeta?.name ?? "hk-events-hk-salary-survey-thankyou___zh-Hant",
    path: thankyou1LV3szJKsmMeta?.path ?? "/zh-Hant/hk-events/hk-salary-survey/thankyou",
    meta: thankyou1LV3szJKsmMeta || {},
    alias: thankyou1LV3szJKsmMeta?.alias || [],
    redirect: thankyou1LV3szJKsmMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hk-salary-survey/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyou1LV3szJKsmMeta?.name ?? "hk-events-hk-salary-survey-thankyou___zh-Hans",
    path: thankyou1LV3szJKsmMeta?.path ?? "/zh-Hans/hk-events/hk-salary-survey/thankyou",
    meta: thankyou1LV3szJKsmMeta || {},
    alias: thankyou1LV3szJKsmMeta?.alias || [],
    redirect: thankyou1LV3szJKsmMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hk-salary-survey/thankyou.vue").then(m => m.default || m)
  },
  {
    name: indexE4VQb5RrSOMeta?.name ?? "hk-events-hktdc-education-career-expo___en",
    path: indexE4VQb5RrSOMeta?.path ?? "/hk-events/hktdc-education-career-expo",
    meta: indexE4VQb5RrSOMeta || {},
    alias: indexE4VQb5RrSOMeta?.alias || [],
    redirect: indexE4VQb5RrSOMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hktdc-education-career-expo/index.vue").then(m => m.default || m)
  },
  {
    name: indexE4VQb5RrSOMeta?.name ?? "hk-events-hktdc-education-career-expo___zh-Hant",
    path: indexE4VQb5RrSOMeta?.path ?? "/zh-Hant/hk-events/hktdc-education-career-expo",
    meta: indexE4VQb5RrSOMeta || {},
    alias: indexE4VQb5RrSOMeta?.alias || [],
    redirect: indexE4VQb5RrSOMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hktdc-education-career-expo/index.vue").then(m => m.default || m)
  },
  {
    name: indexE4VQb5RrSOMeta?.name ?? "hk-events-hktdc-education-career-expo___zh-Hans",
    path: indexE4VQb5RrSOMeta?.path ?? "/zh-Hans/hk-events/hktdc-education-career-expo",
    meta: indexE4VQb5RrSOMeta || {},
    alias: indexE4VQb5RrSOMeta?.alias || [],
    redirect: indexE4VQb5RrSOMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/hktdc-education-career-expo/index.vue").then(m => m.default || m)
  },
  {
    name: my_45talent_45trial_45scheme5TvkkELiRoMeta?.name ?? "hk-events-my-talent-trial-scheme___en",
    path: my_45talent_45trial_45scheme5TvkkELiRoMeta?.path ?? "/hk-events/my-talent-trial-scheme",
    meta: my_45talent_45trial_45scheme5TvkkELiRoMeta || {},
    alias: my_45talent_45trial_45scheme5TvkkELiRoMeta?.alias || [],
    redirect: my_45talent_45trial_45scheme5TvkkELiRoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/my-talent-trial-scheme.vue").then(m => m.default || m)
  },
  {
    name: my_45talent_45trial_45scheme5TvkkELiRoMeta?.name ?? "hk-events-my-talent-trial-scheme___zh-Hant",
    path: my_45talent_45trial_45scheme5TvkkELiRoMeta?.path ?? "/zh-Hant/hk-events/my-talent-trial-scheme",
    meta: my_45talent_45trial_45scheme5TvkkELiRoMeta || {},
    alias: my_45talent_45trial_45scheme5TvkkELiRoMeta?.alias || [],
    redirect: my_45talent_45trial_45scheme5TvkkELiRoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/my-talent-trial-scheme.vue").then(m => m.default || m)
  },
  {
    name: my_45talent_45trial_45scheme5TvkkELiRoMeta?.name ?? "hk-events-my-talent-trial-scheme___zh-Hans",
    path: my_45talent_45trial_45scheme5TvkkELiRoMeta?.path ?? "/zh-Hans/hk-events/my-talent-trial-scheme",
    meta: my_45talent_45trial_45scheme5TvkkELiRoMeta || {},
    alias: my_45talent_45trial_45scheme5TvkkELiRoMeta?.alias || [],
    redirect: my_45talent_45trial_45scheme5TvkkELiRoMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/my-talent-trial-scheme.vue").then(m => m.default || m)
  },
  {
    name: indexgf9VTyfdZEMeta?.name ?? "hk-events-ysip___en",
    path: indexgf9VTyfdZEMeta?.path ?? "/hk-events/ysip",
    meta: indexgf9VTyfdZEMeta || {},
    alias: indexgf9VTyfdZEMeta?.alias || [],
    redirect: indexgf9VTyfdZEMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/ysip/index.vue").then(m => m.default || m)
  },
  {
    name: indexgf9VTyfdZEMeta?.name ?? "hk-events-ysip___zh-Hant",
    path: indexgf9VTyfdZEMeta?.path ?? "/zh-Hant/hk-events/ysip",
    meta: indexgf9VTyfdZEMeta || {},
    alias: indexgf9VTyfdZEMeta?.alias || [],
    redirect: indexgf9VTyfdZEMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/ysip/index.vue").then(m => m.default || m)
  },
  {
    name: indexgf9VTyfdZEMeta?.name ?? "hk-events-ysip___zh-Hans",
    path: indexgf9VTyfdZEMeta?.path ?? "/zh-Hans/hk-events/ysip",
    meta: indexgf9VTyfdZEMeta || {},
    alias: indexgf9VTyfdZEMeta?.alias || [],
    redirect: indexgf9VTyfdZEMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hk-events/ysip/index.vue").then(m => m.default || m)
  },
  {
    name: editu9113WMufzMeta?.name ?? "hradmin-companies-companyId-edit___en",
    path: editu9113WMufzMeta?.path ?? "/hradmin/companies/:companyId()/edit",
    meta: editu9113WMufzMeta || {},
    alias: editu9113WMufzMeta?.alias || [],
    redirect: editu9113WMufzMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editu9113WMufzMeta?.name ?? "hradmin-companies-companyId-edit___zh-Hant",
    path: editu9113WMufzMeta?.path ?? "/zh-Hant/hradmin/companies/:companyId()/edit",
    meta: editu9113WMufzMeta || {},
    alias: editu9113WMufzMeta?.alias || [],
    redirect: editu9113WMufzMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editu9113WMufzMeta?.name ?? "hradmin-companies-companyId-edit___zh-Hans",
    path: editu9113WMufzMeta?.path ?? "/zh-Hans/hradmin/companies/:companyId()/edit",
    meta: editu9113WMufzMeta || {},
    alias: editu9113WMufzMeta?.alias || [],
    redirect: editu9113WMufzMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edituqzYCp34wUMeta?.name ?? "hradmin-companies-companyId-jobs-jobId-edit___en",
    path: edituqzYCp34wUMeta?.path ?? "/hradmin/companies/:companyId()/jobs/:jobId()/edit",
    meta: edituqzYCp34wUMeta || {},
    alias: edituqzYCp34wUMeta?.alias || [],
    redirect: edituqzYCp34wUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/[jobId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edituqzYCp34wUMeta?.name ?? "hradmin-companies-companyId-jobs-jobId-edit___zh-Hant",
    path: edituqzYCp34wUMeta?.path ?? "/zh-Hant/hradmin/companies/:companyId()/jobs/:jobId()/edit",
    meta: edituqzYCp34wUMeta || {},
    alias: edituqzYCp34wUMeta?.alias || [],
    redirect: edituqzYCp34wUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/[jobId]/edit.vue").then(m => m.default || m)
  },
  {
    name: edituqzYCp34wUMeta?.name ?? "hradmin-companies-companyId-jobs-jobId-edit___zh-Hans",
    path: edituqzYCp34wUMeta?.path ?? "/zh-Hans/hradmin/companies/:companyId()/jobs/:jobId()/edit",
    meta: edituqzYCp34wUMeta || {},
    alias: edituqzYCp34wUMeta?.alias || [],
    redirect: edituqzYCp34wUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/[jobId]/edit.vue").then(m => m.default || m)
  },
  {
    name: createwiJ58rLefnMeta?.name ?? "hradmin-companies-companyId-jobs-create___en",
    path: createwiJ58rLefnMeta?.path ?? "/hradmin/companies/:companyId()/jobs/create",
    meta: createwiJ58rLefnMeta || {},
    alias: createwiJ58rLefnMeta?.alias || [],
    redirect: createwiJ58rLefnMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: createwiJ58rLefnMeta?.name ?? "hradmin-companies-companyId-jobs-create___zh-Hant",
    path: createwiJ58rLefnMeta?.path ?? "/zh-Hant/hradmin/companies/:companyId()/jobs/create",
    meta: createwiJ58rLefnMeta || {},
    alias: createwiJ58rLefnMeta?.alias || [],
    redirect: createwiJ58rLefnMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: createwiJ58rLefnMeta?.name ?? "hradmin-companies-companyId-jobs-create___zh-Hans",
    path: createwiJ58rLefnMeta?.path ?? "/zh-Hans/hradmin/companies/:companyId()/jobs/create",
    meta: createwiJ58rLefnMeta || {},
    alias: createwiJ58rLefnMeta?.alias || [],
    redirect: createwiJ58rLefnMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/[companyId]/jobs/create.vue").then(m => m.default || m)
  },
  {
    name: createlHvDoraHcTMeta?.name ?? "hradmin-companies-create___en",
    path: createlHvDoraHcTMeta?.path ?? "/hradmin/companies/create",
    meta: createlHvDoraHcTMeta || {},
    alias: createlHvDoraHcTMeta?.alias || [],
    redirect: createlHvDoraHcTMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createlHvDoraHcTMeta?.name ?? "hradmin-companies-create___zh-Hant",
    path: createlHvDoraHcTMeta?.path ?? "/zh-Hant/hradmin/companies/create",
    meta: createlHvDoraHcTMeta || {},
    alias: createlHvDoraHcTMeta?.alias || [],
    redirect: createlHvDoraHcTMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: createlHvDoraHcTMeta?.name ?? "hradmin-companies-create___zh-Hans",
    path: createlHvDoraHcTMeta?.path ?? "/zh-Hans/hradmin/companies/create",
    meta: createlHvDoraHcTMeta || {},
    alias: createlHvDoraHcTMeta?.alias || [],
    redirect: createlHvDoraHcTMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/companies/create.vue").then(m => m.default || m)
  },
  {
    name: indexajTMSjDBrGMeta?.name ?? "hradmin___en",
    path: indexajTMSjDBrGMeta?.path ?? "/hradmin",
    meta: indexajTMSjDBrGMeta || {},
    alias: indexajTMSjDBrGMeta?.alias || [],
    redirect: indexajTMSjDBrGMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/index.vue").then(m => m.default || m)
  },
  {
    name: indexajTMSjDBrGMeta?.name ?? "hradmin___zh-Hant",
    path: indexajTMSjDBrGMeta?.path ?? "/zh-Hant/hradmin",
    meta: indexajTMSjDBrGMeta || {},
    alias: indexajTMSjDBrGMeta?.alias || [],
    redirect: indexajTMSjDBrGMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/index.vue").then(m => m.default || m)
  },
  {
    name: indexajTMSjDBrGMeta?.name ?? "hradmin___zh-Hans",
    path: indexajTMSjDBrGMeta?.path ?? "/zh-Hans/hradmin",
    meta: indexajTMSjDBrGMeta || {},
    alias: indexajTMSjDBrGMeta?.alias || [],
    redirect: indexajTMSjDBrGMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/hradmin/index.vue").then(m => m.default || m)
  },
  {
    name: indexfham10MBRkMeta?.name ?? "index___en",
    path: indexfham10MBRkMeta?.path ?? "/",
    meta: indexfham10MBRkMeta || {},
    alias: indexfham10MBRkMeta?.alias || [],
    redirect: indexfham10MBRkMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfham10MBRkMeta?.name ?? "index___zh-Hant",
    path: indexfham10MBRkMeta?.path ?? "/zh-Hant",
    meta: indexfham10MBRkMeta || {},
    alias: indexfham10MBRkMeta?.alias || [],
    redirect: indexfham10MBRkMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfham10MBRkMeta?.name ?? "index___zh-Hans",
    path: indexfham10MBRkMeta?.path ?? "/zh-Hans",
    meta: indexfham10MBRkMeta || {},
    alias: indexfham10MBRkMeta?.alias || [],
    redirect: indexfham10MBRkMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bKUyM9UfylMeta?.name ?? "job-campaigns-id___en",
    path: _91id_93bKUyM9UfylMeta?.path ?? "/job-campaigns/:id()",
    meta: _91id_93bKUyM9UfylMeta || {},
    alias: _91id_93bKUyM9UfylMeta?.alias || [],
    redirect: _91id_93bKUyM9UfylMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/job-campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bKUyM9UfylMeta?.name ?? "job-campaigns-id___zh-Hant",
    path: _91id_93bKUyM9UfylMeta?.path ?? "/zh-Hant/job-campaigns/:id()",
    meta: _91id_93bKUyM9UfylMeta || {},
    alias: _91id_93bKUyM9UfylMeta?.alias || [],
    redirect: _91id_93bKUyM9UfylMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/job-campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bKUyM9UfylMeta?.name ?? "job-campaigns-id___zh-Hans",
    path: _91id_93bKUyM9UfylMeta?.path ?? "/zh-Hans/job-campaigns/:id()",
    meta: _91id_93bKUyM9UfylMeta || {},
    alias: _91id_93bKUyM9UfylMeta?.alias || [],
    redirect: _91id_93bKUyM9UfylMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/job-campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kVSeuP14tIMeta?.name ?? "jobs-id___en",
    path: _91id_93kVSeuP14tIMeta?.path ?? "/jobs/:id()",
    meta: _91id_93kVSeuP14tIMeta || {},
    alias: _91id_93kVSeuP14tIMeta?.alias || [],
    redirect: _91id_93kVSeuP14tIMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kVSeuP14tIMeta?.name ?? "jobs-id___zh-Hant",
    path: _91id_93kVSeuP14tIMeta?.path ?? "/zh-Hant/jobs/:id()",
    meta: _91id_93kVSeuP14tIMeta || {},
    alias: _91id_93kVSeuP14tIMeta?.alias || [],
    redirect: _91id_93kVSeuP14tIMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kVSeuP14tIMeta?.name ?? "jobs-id___zh-Hans",
    path: _91id_93kVSeuP14tIMeta?.path ?? "/zh-Hans/jobs/:id()",
    meta: _91id_93kVSeuP14tIMeta || {},
    alias: _91id_93kVSeuP14tIMeta?.alias || [],
    redirect: _91id_93kVSeuP14tIMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmViOkg1KsxMeta?.name ?? "jobs___en",
    path: indexmViOkg1KsxMeta?.path ?? "/jobs",
    meta: indexmViOkg1KsxMeta || {},
    alias: indexmViOkg1KsxMeta?.alias || [],
    redirect: indexmViOkg1KsxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexmViOkg1KsxMeta?.name ?? "jobs___zh-Hant",
    path: indexmViOkg1KsxMeta?.path ?? "/zh-Hant/jobs",
    meta: indexmViOkg1KsxMeta || {},
    alias: indexmViOkg1KsxMeta?.alias || [],
    redirect: indexmViOkg1KsxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexmViOkg1KsxMeta?.name ?? "jobs___zh-Hans",
    path: indexmViOkg1KsxMeta?.path ?? "/zh-Hans/jobs",
    meta: indexmViOkg1KsxMeta || {},
    alias: indexmViOkg1KsxMeta?.alias || [],
    redirect: indexmViOkg1KsxMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: _91title_93uQ2qkd0VL8Meta?.name ?? "jobs-searches-title___en",
    path: _91title_93uQ2qkd0VL8Meta?.path ?? "/jobs/searches/:title()",
    meta: _91title_93uQ2qkd0VL8Meta || {},
    alias: _91title_93uQ2qkd0VL8Meta?.alias || [],
    redirect: _91title_93uQ2qkd0VL8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/searches/[title].vue").then(m => m.default || m)
  },
  {
    name: _91title_93uQ2qkd0VL8Meta?.name ?? "jobs-searches-title___zh-Hant",
    path: _91title_93uQ2qkd0VL8Meta?.path ?? "/zh-Hant/jobs/searches/:title()",
    meta: _91title_93uQ2qkd0VL8Meta || {},
    alias: _91title_93uQ2qkd0VL8Meta?.alias || [],
    redirect: _91title_93uQ2qkd0VL8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/searches/[title].vue").then(m => m.default || m)
  },
  {
    name: _91title_93uQ2qkd0VL8Meta?.name ?? "jobs-searches-title___zh-Hans",
    path: _91title_93uQ2qkd0VL8Meta?.path ?? "/zh-Hans/jobs/searches/:title()",
    meta: _91title_93uQ2qkd0VL8Meta || {},
    alias: _91title_93uQ2qkd0VL8Meta?.alias || [],
    redirect: _91title_93uQ2qkd0VL8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/jobs/searches/[title].vue").then(m => m.default || m)
  },
  {
    name: companyu2kbWHlsJ5Meta?.name ?? "onboard-company___en",
    path: companyu2kbWHlsJ5Meta?.path ?? "/onboard/company",
    meta: companyu2kbWHlsJ5Meta || {},
    alias: companyu2kbWHlsJ5Meta?.alias || [],
    redirect: companyu2kbWHlsJ5Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/onboard/company.vue").then(m => m.default || m)
  },
  {
    name: companyu2kbWHlsJ5Meta?.name ?? "onboard-company___zh-Hant",
    path: companyu2kbWHlsJ5Meta?.path ?? "/zh-Hant/onboard/company",
    meta: companyu2kbWHlsJ5Meta || {},
    alias: companyu2kbWHlsJ5Meta?.alias || [],
    redirect: companyu2kbWHlsJ5Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/onboard/company.vue").then(m => m.default || m)
  },
  {
    name: companyu2kbWHlsJ5Meta?.name ?? "onboard-company___zh-Hans",
    path: companyu2kbWHlsJ5Meta?.path ?? "/zh-Hans/onboard/company",
    meta: companyu2kbWHlsJ5Meta || {},
    alias: companyu2kbWHlsJ5Meta?.alias || [],
    redirect: companyu2kbWHlsJ5Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/onboard/company.vue").then(m => m.default || m)
  },
  {
    name: resume9QBqUrJPfDMeta?.name ?? "onboard-resume___en",
    path: resume9QBqUrJPfDMeta?.path ?? "/onboard/resume",
    meta: resume9QBqUrJPfDMeta || {},
    alias: resume9QBqUrJPfDMeta?.alias || [],
    redirect: resume9QBqUrJPfDMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/onboard/resume.vue").then(m => m.default || m)
  },
  {
    name: resume9QBqUrJPfDMeta?.name ?? "onboard-resume___zh-Hant",
    path: resume9QBqUrJPfDMeta?.path ?? "/zh-Hant/onboard/resume",
    meta: resume9QBqUrJPfDMeta || {},
    alias: resume9QBqUrJPfDMeta?.alias || [],
    redirect: resume9QBqUrJPfDMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/onboard/resume.vue").then(m => m.default || m)
  },
  {
    name: resume9QBqUrJPfDMeta?.name ?? "onboard-resume___zh-Hans",
    path: resume9QBqUrJPfDMeta?.path ?? "/zh-Hans/onboard/resume",
    meta: resume9QBqUrJPfDMeta || {},
    alias: resume9QBqUrJPfDMeta?.alias || [],
    redirect: resume9QBqUrJPfDMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/onboard/resume.vue").then(m => m.default || m)
  },
  {
    name: payment_45resultsLz64xtb6eMeta?.name ?? "payment-result___en",
    path: payment_45resultsLz64xtb6eMeta?.path ?? "/payment-result",
    meta: payment_45resultsLz64xtb6eMeta || {},
    alias: payment_45resultsLz64xtb6eMeta?.alias || [],
    redirect: payment_45resultsLz64xtb6eMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: payment_45resultsLz64xtb6eMeta?.name ?? "payment-result___zh-Hant",
    path: payment_45resultsLz64xtb6eMeta?.path ?? "/zh-Hant/payment-result",
    meta: payment_45resultsLz64xtb6eMeta || {},
    alias: payment_45resultsLz64xtb6eMeta?.alias || [],
    redirect: payment_45resultsLz64xtb6eMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: payment_45resultsLz64xtb6eMeta?.name ?? "payment-result___zh-Hans",
    path: payment_45resultsLz64xtb6eMeta?.path ?? "/zh-Hans/payment-result",
    meta: payment_45resultsLz64xtb6eMeta || {},
    alias: payment_45resultsLz64xtb6eMeta?.alias || [],
    redirect: payment_45resultsLz64xtb6eMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyb4icD8scJ8Meta?.name ?? "privacy-policy___en",
    path: privacy_45policyb4icD8scJ8Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyb4icD8scJ8Meta || {},
    alias: privacy_45policyb4icD8scJ8Meta?.alias || [],
    redirect: privacy_45policyb4icD8scJ8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyb4icD8scJ8Meta?.name ?? "privacy-policy___zh-Hant",
    path: privacy_45policyb4icD8scJ8Meta?.path ?? "/zh-Hant/privacy-policy",
    meta: privacy_45policyb4icD8scJ8Meta || {},
    alias: privacy_45policyb4icD8scJ8Meta?.alias || [],
    redirect: privacy_45policyb4icD8scJ8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyb4icD8scJ8Meta?.name ?? "privacy-policy___zh-Hans",
    path: privacy_45policyb4icD8scJ8Meta?.path ?? "/zh-Hans/privacy-policy",
    meta: privacy_45policyb4icD8scJ8Meta || {},
    alias: privacy_45policyb4icD8scJ8Meta?.alias || [],
    redirect: privacy_45policyb4icD8scJ8Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexEiyAg9I6W9Meta?.name ?? "profile___en",
    path: indexEiyAg9I6W9Meta?.path ?? "/profile",
    meta: indexEiyAg9I6W9Meta || {},
    alias: indexEiyAg9I6W9Meta?.alias || [],
    redirect: indexEiyAg9I6W9Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiyAg9I6W9Meta?.name ?? "profile___zh-Hant",
    path: indexEiyAg9I6W9Meta?.path ?? "/zh-Hant/profile",
    meta: indexEiyAg9I6W9Meta || {},
    alias: indexEiyAg9I6W9Meta?.alias || [],
    redirect: indexEiyAg9I6W9Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiyAg9I6W9Meta?.name ?? "profile___zh-Hans",
    path: indexEiyAg9I6W9Meta?.path ?? "/zh-Hans/profile",
    meta: indexEiyAg9I6W9Meta || {},
    alias: indexEiyAg9I6W9Meta?.alias || [],
    redirect: indexEiyAg9I6W9Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: companiesanUQlyIq77Meta?.name ?? "sitemap-companies___en",
    path: companiesanUQlyIq77Meta?.path ?? "/sitemap/companies",
    meta: companiesanUQlyIq77Meta || {},
    alias: companiesanUQlyIq77Meta?.alias || [],
    redirect: companiesanUQlyIq77Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesanUQlyIq77Meta?.name ?? "sitemap-companies___zh-Hant",
    path: companiesanUQlyIq77Meta?.path ?? "/zh-Hant/sitemap/companies",
    meta: companiesanUQlyIq77Meta || {},
    alias: companiesanUQlyIq77Meta?.alias || [],
    redirect: companiesanUQlyIq77Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/companies.vue").then(m => m.default || m)
  },
  {
    name: companiesanUQlyIq77Meta?.name ?? "sitemap-companies___zh-Hans",
    path: companiesanUQlyIq77Meta?.path ?? "/zh-Hans/sitemap/companies",
    meta: companiesanUQlyIq77Meta || {},
    alias: companiesanUQlyIq77Meta?.alias || [],
    redirect: companiesanUQlyIq77Meta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/companies.vue").then(m => m.default || m)
  },
  {
    name: job_45campaigns16gJ9GfHzuMeta?.name ?? "sitemap-job-campaigns___en",
    path: job_45campaigns16gJ9GfHzuMeta?.path ?? "/sitemap/job-campaigns",
    meta: job_45campaigns16gJ9GfHzuMeta || {},
    alias: job_45campaigns16gJ9GfHzuMeta?.alias || [],
    redirect: job_45campaigns16gJ9GfHzuMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/job-campaigns.vue").then(m => m.default || m)
  },
  {
    name: job_45campaigns16gJ9GfHzuMeta?.name ?? "sitemap-job-campaigns___zh-Hant",
    path: job_45campaigns16gJ9GfHzuMeta?.path ?? "/zh-Hant/sitemap/job-campaigns",
    meta: job_45campaigns16gJ9GfHzuMeta || {},
    alias: job_45campaigns16gJ9GfHzuMeta?.alias || [],
    redirect: job_45campaigns16gJ9GfHzuMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/job-campaigns.vue").then(m => m.default || m)
  },
  {
    name: job_45campaigns16gJ9GfHzuMeta?.name ?? "sitemap-job-campaigns___zh-Hans",
    path: job_45campaigns16gJ9GfHzuMeta?.path ?? "/zh-Hans/sitemap/job-campaigns",
    meta: job_45campaigns16gJ9GfHzuMeta || {},
    alias: job_45campaigns16gJ9GfHzuMeta?.alias || [],
    redirect: job_45campaigns16gJ9GfHzuMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/job-campaigns.vue").then(m => m.default || m)
  },
  {
    name: jobsEh1fEvpUHUMeta?.name ?? "sitemap-jobs___en",
    path: jobsEh1fEvpUHUMeta?.path ?? "/sitemap/jobs",
    meta: jobsEh1fEvpUHUMeta || {},
    alias: jobsEh1fEvpUHUMeta?.alias || [],
    redirect: jobsEh1fEvpUHUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsEh1fEvpUHUMeta?.name ?? "sitemap-jobs___zh-Hant",
    path: jobsEh1fEvpUHUMeta?.path ?? "/zh-Hant/sitemap/jobs",
    meta: jobsEh1fEvpUHUMeta || {},
    alias: jobsEh1fEvpUHUMeta?.alias || [],
    redirect: jobsEh1fEvpUHUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/jobs.vue").then(m => m.default || m)
  },
  {
    name: jobsEh1fEvpUHUMeta?.name ?? "sitemap-jobs___zh-Hans",
    path: jobsEh1fEvpUHUMeta?.path ?? "/zh-Hans/sitemap/jobs",
    meta: jobsEh1fEvpUHUMeta || {},
    alias: jobsEh1fEvpUHUMeta?.alias || [],
    redirect: jobsEh1fEvpUHUMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/jobs.vue").then(m => m.default || m)
  },
  {
    name: popular_45searchesX0BqCBu0erMeta?.name ?? "sitemap-popular-searches___en",
    path: popular_45searchesX0BqCBu0erMeta?.path ?? "/sitemap/popular-searches",
    meta: popular_45searchesX0BqCBu0erMeta || {},
    alias: popular_45searchesX0BqCBu0erMeta?.alias || [],
    redirect: popular_45searchesX0BqCBu0erMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/popular-searches.vue").then(m => m.default || m)
  },
  {
    name: popular_45searchesX0BqCBu0erMeta?.name ?? "sitemap-popular-searches___zh-Hant",
    path: popular_45searchesX0BqCBu0erMeta?.path ?? "/zh-Hant/sitemap/popular-searches",
    meta: popular_45searchesX0BqCBu0erMeta || {},
    alias: popular_45searchesX0BqCBu0erMeta?.alias || [],
    redirect: popular_45searchesX0BqCBu0erMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/popular-searches.vue").then(m => m.default || m)
  },
  {
    name: popular_45searchesX0BqCBu0erMeta?.name ?? "sitemap-popular-searches___zh-Hans",
    path: popular_45searchesX0BqCBu0erMeta?.path ?? "/zh-Hans/sitemap/popular-searches",
    meta: popular_45searchesX0BqCBu0erMeta || {},
    alias: popular_45searchesX0BqCBu0erMeta?.alias || [],
    redirect: popular_45searchesX0BqCBu0erMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/sitemap/popular-searches.vue").then(m => m.default || m)
  },
  {
    name: termsAOJa1iRFbaMeta?.name ?? "terms___en",
    path: termsAOJa1iRFbaMeta?.path ?? "/terms",
    meta: termsAOJa1iRFbaMeta || {},
    alias: termsAOJa1iRFbaMeta?.alias || [],
    redirect: termsAOJa1iRFbaMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsAOJa1iRFbaMeta?.name ?? "terms___zh-Hant",
    path: termsAOJa1iRFbaMeta?.path ?? "/zh-Hant/terms",
    meta: termsAOJa1iRFbaMeta || {},
    alias: termsAOJa1iRFbaMeta?.alias || [],
    redirect: termsAOJa1iRFbaMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsAOJa1iRFbaMeta?.name ?? "terms___zh-Hans",
    path: termsAOJa1iRFbaMeta?.path ?? "/zh-Hans/terms",
    meta: termsAOJa1iRFbaMeta || {},
    alias: termsAOJa1iRFbaMeta?.alias || [],
    redirect: termsAOJa1iRFbaMeta?.redirect,
    component: () => import("/mono-repo/frontend/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: component_45stubkZ42uMcRISMeta?.name ?? undefined,
    path: component_45stubkZ42uMcRISMeta?.path ?? "/sitemap.xml",
    meta: component_45stubkZ42uMcRISMeta || {},
    alias: component_45stubkZ42uMcRISMeta?.alias || [],
    redirect: component_45stubkZ42uMcRISMeta?.redirect,
    component: component_45stubkZ42uMcRIS
  }
]